<template>
  <div >
      <div class="title"> 大事记</div>
      <img class="imgstyle" :src="img" alt="">
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "大事记",
  },
  data(){
    return{
      img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/Memorabilia.png'
    }
  }
  
};
</script>
<style scoped lang="less">
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
</style>