import { render, staticRenderFns } from "./memorabilia.vue?vue&type=template&id=eb8944ee&scoped=true"
import script from "./memorabilia.vue?vue&type=script&lang=js"
export * from "./memorabilia.vue?vue&type=script&lang=js"
import style0 from "./memorabilia.vue?vue&type=style&index=0&id=eb8944ee&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb8944ee",
  null
  
)

export default component.exports